
import { Options, Vue } from 'vue-class-component'
import theEvent from '@/components/Frontend/Event/index.vue'

@Options({
  components: {
    theEvent
  }
})

export default class Event extends Vue {}
