import { Options, Vue } from 'vue-class-component'
import { APIURL } from '@/components/Utility/APIBase'
import CommonFunctions from '@/components/Utility/Common'
import { Events } from '@/services/EventsDataService'
import SysEvent, { SysEventEventType } from '@/types/SysEvent'
import { useRoute } from 'vue-router'

type eventDataType = { eventStatus: boolean; eventTitle: string; eventDescription: string; eventLocation: string; eventPrice: string; eventTypeTitle: string; eventStart: string; eventEnd: string; clubEmail: string; clubTelefon: string; clubName: string; clubSlug: string; eventInvitation: any[]; extraContactTelephone: string; extraContactEmail: string; }
type dataReturnType = { APIURL: string; error: any; eventData: eventDataType; }

export default class theEvent extends Vue {
  private eventSlug = 0
  error: any = null
  private tempEvent = {} as SysEvent
  eventData: eventDataType = { eventStatus: true, eventTitle: '', eventDescription: '', eventLocation: '', eventPrice: '0', eventTypeTitle: '', eventStart: '', eventEnd: '', clubEmail: '', clubTelefon: '', clubName: '', clubSlug: '', eventInvitation: [], extraContactTelephone: '', extraContactEmail: '' }

  readonly name : string = 'theEvent'
  data (): dataReturnType {
    return {
      APIURL: APIURL,
      error: this.error,
      eventData: this.eventData
    }
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    console.log('[locConvertFromUTCtoLocalDateTime()] inputDatetimeString = ' + inputDatetimeString)
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public danishDataWClock (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 5) + ' kl. ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public retrieveEvent () : void {
    this.eventSlug = 0
    const route = useRoute()
    this.eventSlug = parseInt(route.params.slug.toString())

    Events.EventsDataService.get(this.eventSlug.toString())
      .then((response) => {
        this.tempEvent = response.data

        this.eventData = {
          eventStatus: this.tempEvent.event_status,
          eventTitle: this.tempEvent.event_titel,
          eventDescription: this.tempEvent.event_beskrivelse,
          eventLocation: this.tempEvent.event_sted,
          eventPrice: this.tempEvent.event_pris.toString(),
          eventTypeTitle: this.tempEvent.event_type_id.event_type_titel,
          eventStart: this.tempEvent.event_start,
          eventEnd: this.tempEvent.event_slut,
          clubEmail: this.tempEvent.klubber_id === null ? '' : this.tempEvent.klubber_id.klubber_email,
          clubTelefon: this.tempEvent.klubber_id === null ? '' : this.tempEvent.klubber_id.klubber_telefonnummer,
          clubName: this.tempEvent.klubber_id === null ? '' : this.tempEvent.klubber_id.klubber_klubnavn,
          clubSlug: this.tempEvent.klubber_id === null ? '' : this.tempEvent.klubber_id.klubber_slug,
          eventInvitation: this.tempEvent.event_invitation,
          extraContactTelephone: this.tempEvent.event_kontakt_telefon === null ? '' : this.tempEvent.event_kontakt_telefon,
          extraContactEmail: this.tempEvent.event_tilmelding_mail === null ? '' : this.tempEvent.event_tilmelding_mail
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  async mounted () : Promise<void> {
    this.retrieveEvent()
  }
}
